import React, { useState } from 'react';
import styled from 'styled-components';
import Ornament from './Ornament';
import ChildInfoModal from './modals/ChildInfoModal';
import { OrnamentItem, useGetTreeData } from './services/api';
import { getOrnamentWidth, limitOrnamentCount } from './services/ornament';
import Twinkle from './components/Twinkle';

const UNDER_CONSTRUCTION = false;
export const MAX_ORNAMENT_COUNT = 100;
const OrnamentArea = () => {
  /*
    local state
  */
  const [currentChild, setCurrentChild] = useState<OrnamentItem>(null);
  const handleOpenModal = (ornament: OrnamentItem) => {
    setCurrentChild(ornament);
  };

  /*
    API calls
  */
  const { data } = useGetTreeData({ refetchOnMount: 'always' });
  const { responseData, allDonated } = data ?? {};

  /*
    derived state
  */
  const visibleOrnaments = React.useMemo(() => {
    let ornaments = [];
    if (responseData?.ids.length) {
      responseData.ids.forEach(id => ornaments.push(...responseData.details[id]));
    }
    if (responseData?.ids.length > MAX_ORNAMENT_COUNT) {
      return limitOrnamentCount(ornaments, MAX_ORNAMENT_COUNT);
    }
    return ornaments;
  }, [responseData]);
  const ornamentWidth = getOrnamentWidth(visibleOrnaments?.length ?? 0);

  return UNDER_CONSTRUCTION ? (
    <Maintenance>We'll be right back...</Maintenance>
  ) : (
    <>
      <Container>
        {data ? (
          visibleOrnaments.map(ornament => (
            <Ornament
              key={ornament.id + ornament.itemType}
              width={ornamentWidth}
              child={ornament}
              onDetailsClick={() => handleOpenModal(ornament)}
              allDonated={allDonated}
            />
          ))
        ) : (
          <Twinkle />
        )}
      </Container>

      {currentChild && (
        <ChildInfoModal currentChild={currentChild} handleClose={() => setCurrentChild(null)} />
      )}
    </>
  );
};

const Container = styled.ul`
  width: 100%;
  height: 100%;
  text-align: center;
`;

const Maintenance = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

export default OrnamentArea;
