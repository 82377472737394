import { dayjsExtended } from './utils';

export const ENDPOINT_LOCAL = 'http://andre-mac.local:3001';
export const ENDPOINT = 'https://by8f7az6rh.execute-api.us-west-1.amazonaws.com/Prod/';
export const SITE_BANNER = 'Lamping Giving Tree 2024';
const SCHOOL_ADDRESS = '2551 Summit Grove Dr. Henderson, NV 89052';
export const DEADLINE = '2024-12-11 18:00';
export const TIMEZONE = 'America/Los_Angeles';
export const donationDeadline = dayjsExtended.tz(DEADLINE, TIMEZONE).format('MMM Do hA');
export const SUPPORT_EMAIL = 'fendtlk@nv.ccsd.net';
export const INSTRUCTIONS = `Thank you for adopting an angel this year.

Fill out required information, wrap your donated gifts, write on a gift tag the donation id#, and attach to your gifts.

Please bring your donated gifts to our campus located at ${SCHOOL_ADDRESS} as soon as possible or by the deadline ${donationDeadline}.

If you are in need of gift boxes or gift bags for your donated gifts, you can come and pick some up at our school before the deadline. Thank you!
`;

export const SEPARATE_TOYS_APPAREL = true;
export const SCHOOL_OPTIONS = ['Lamping', 'Galloway'];
export const TEACHER_GUARDIAN_INFO = true;
